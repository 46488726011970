//modal info de celda de tabla
.c-modal-info-cell{
  width: 98%;
  max-width: 50.250rem;
  //max-width:41.5625rem;
  position: absolute;
  z-index: 999;
  padding: 0.938rem;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .44);
  border-radius: $border-radius;

  //header modal
  .c-modal-info-cell__header{
    position: relative;
    //media queries
    @include mq('phone-wide'){
      flex-direction: row;
    }
    //tabla con nombre establecimiento y direccion completa
    &__table{
      margin-bottom: 8px;
      thead{
        th{
          font-size:0.75rem;
          color: $color-gray-light;
          line-height: 14px;
          font-weight: 400;
          padding: 5px 0;
        }
      }
      tbody{
        td{
          font-size:0.75rem;
          font-weight: 400;
          &:nth-child(1){
            padding-right: 10px;
          }
        }
      }
    }
    &__info{
      &-left{
        width: calc(100% - 140px);
      }
      &-right{
        max-width:140px;
        .btn{
          width:56px;
          height:38px;
          span{
            font-size: 15px;
          }
        }
      }
    }
    .c-modal-info-cell__header__right{
      display: flex;
      justify-content: center;
      position: absolute;
      right:0;
      top:0;
      .c-modal-info-cell__square{
        width: auto;
        max-width: 5rem;
        height: 2.188rem;
        line-height: 2.188rem;
        padding: 0 0.625rem;
        margin: 0 0.438rem 0.4rem 0;
        background-color: $bg-white;
        border: $border-width $border-style #CED4DA;
        border-radius: $border-radius;
        font-family: $font-base;
        font-size: 0.875rem;
        font-weight: 400;
        color: $font-color-dark;
        &:last-child{
          margin-right: 0;
          margin-left: 0.5rem;
        }
        &.user{
          .fa-user-check{
            color: $font-color-blue-dark;
          }
          .fa-user{
            color: $color-drag-item;
          }
        }
      }
    }
  }

  //arrow top por defecto
  &::before,
  &::after{
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
  }
  &::before{
    content:"";
    top: -20px;
    left: 5px;
    z-index:9999;
    border-width: 0 15px 22px 15px;
    border-color: transparent transparent #fff transparent;

  }
  &::after{
    top: -23px;
    left: 5px;
    border-width: 0 16px 24px 16px;
    border-color: transparent transparent rgba(0, 0, 0, 0.08) transparent;
    z-index:9998;
  }
  //arrow top arriba a la derecha
  &.flecha-arriba.flecha-derecha{
    &::before,
    &::after{
      content: '';
      left: auto;
      right: 5px;
    }
  }
  //arrow top abajo a la izquierda
  &.flecha-debajo.flecha-izquierda{
    &::before{
      content: '';
      top: auto;
      bottom: -20px;
      border-width: 22px 15px 0 15px;
      border-color: #fff  transparent transparent  transparent;
    }
    &::after{
      top: auto;
      bottom: -24px;
      border-width: 24px 16px 0 16px;
      border-color: rgba(0, 0, 0, 0.08) transparent transparent transparent;
      z-index:9998;
    }
  }
  //arrow top abajo a la derecha
  &.flecha-debajo.flecha-derecha{
    @extend .flecha-debajo.flecha-izquierda;
    &::before,
    &::after{
      content: '';
      left: auto;
      right: 5px;
    }
  }

}

//body modal
.c-modal-info__cell__body{
  margin-top: 0;
  //caja de cada orden
  .c-modal-info-cell__box{
    border: $border-width $border-style $color-blue-dark; //1.5px
    border-radius: $border-radius;
    padding: 0.4rem 1.8rem 0.4rem 0.4rem; //7px
    margin-bottom:0.4375rem;
    // en la seccion calendario otro estilo
    &--schedule{
      border:1px solid #FDDADC;
      .c-modal-info-cell__text{
        font-size: 0.85rem;
      }
    }
  }

  // nueva tabla
  &__table{
    th{
      font-size:0.75rem;
      color: $color-gray-light;
      line-height: 14px;
      font-weight: 400;
      padding: 5px 0;
      text-transform: capitalize;
    }
    td{
      text-transform: capitalize;
      color:$color-gray-dark;
      font-size:12px;
      font-weight:600;
      border:0;
      padding:1px;
    }
    // seccion color
    .type-colour{
      background: red;
      width:16px;
      height:16px;
    }
    //texto comentarios
    .table-comment{
      font-size:12px;
      line-height:20px;
      color: $color-blue-medium;
      margin: 0;
      font-weight:600;
      span{
        font-weight:400;
        font-style:italic;
      }
    }
    .table-comment-dispatcher{
      font-size:12px;
      line-height:20px;
      color: $color-red-dark;
      margin: 0;
      font-weight:600;
      span{
        font-weight:400;
        font-style:italic;
      }
    }
  }
}







