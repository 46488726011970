//listado de checkboxes que se arrastran en modal left
.drag-li {
  list-style-type: none;
  font-family: $font-base;
  font-size: 0.875rem;
  font-weight: 400;
  color: $color-xdark;
  position: relative;
  z-index: 999;

 .non-selectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    position: relative;
    margin-right: 2rem;
    background-color: #dee2e6;

    &:checked {
      background-color: $color-blue;

      &::before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: $bg-white;
      }
    }
  }

  label {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }
}


