//título
.title-wrapper {
  padding: 0.714em 0.929em; //10px y 13px
  font-size: $font-size;
  border: 1px solid #e7e7e7;
  border-radius: $border-radius;
  background-color: $bg-title;

  h3.form-title,
  .form-title {
    margin: 0;
    padding: 0;
    font-family: $font-base;
    font-size: 0.875em; //14px
    font-weight: 600;
    color: $title-form-color;

    span {
      font-weight: 500;
    }
  }

  .fas,
  .far,
  .fa {
    color: #888;
    opacity: .65;
  }
}

//título pequeño
h3.small-title,
.small-title {
  margin-bottom: 0.4rem;
  font-family: $font-base;
  font-size: 1em; //14px
  font-weight: 500;
  color: $font-color-dark;
}

//labels
label,
.p-label {
  font-family: $font-base;
  font-size: 0.8rem;
  font-weight: 400;
  color: $color-xdark;
}

//radiobutton
.radiobutton {
  position: relative;
  font-size: $font-size-small;
  @include mq('tablet') {
    font-size: $font-size;
  }

  label {
    order: 2;
    margin-bottom: 0;
    padding-left: 2.129em;
    font-family: $font-base;
    font-size: 0.875em; //14px
    font-weight: 400;
    line-height: 130%;
    text-align: left;
    //text-transform: capitalize;
    color: $font-color;
    cursor: pointer;
    //media queries
    @include mq('tablet') {
      font-size: 1em;
    }
  }

  [type="radio"],
  [type="checkbox"],
  .form-check-input {
    width: auto;
    height: auto;

    &:checked,
    &:not(:checked) {
      position: absolute;
      left: -9999px;
      @include appearance();

      + label {
        position: relative;

        &::before {
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          left: 0;
          top: 0;
          border: 1px solid $color-blue;
          border-radius: 50%;
          background: $bg-white;
        }

        &::after {
          content: '';
          width: 10px;
          height: 10px;
          background: $color-blue;
          position: absolute;
          top: 3px;
          left: 3px;
          border-radius: 50%;
          transition: all 0.2s ease;
        }
      }
    }

    &:not(:checked) {
      + label {
        &::after {
          opacity: 0;
          transform: scale(0);
        }
      }
    }

    &:checked {
      + label {
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

//checkboxes
.checkbox {
  @extend .radiobutton;

  label {
    padding-left: 3.629em;

    &.pl-less {
      padding-left: 1.629em;
      font-size: 0.8rem;
      line-height: 16px; //altura checkbox
    }
  }

  [type="checkbox"] {
    &:checked,
    &:not(:checked) {
      + label {
        &::before,
        &::after {
          border-radius: 0;
        }

        &::before {
          width: 14px;
          height: 14px;
        }

        &::after {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
}

//select
.custom-select,
select.custom-select {
  @include appearance();
}

//focus de inputs
input,
select,
textarea,
.select-control {
  &.form-control {
    @include focus();
  }

  &.custom-select {
    @extend .form-control;
  }

}

::placeholder {
  color: #CED4DA;
}

.custom-file {
  .custom-file-label {
    @include focus();
  }

  .custom-file-input {
    @extend .custom-file-label;
  }
}

//inputs no editables
.no-event {
  cursor: not-allowed;

  input,
  .form-control {
    pointer-events: none;
    border: 0;
  }
}

//form-row con borde
.form-row {
  &.with-border {
    padding: 0.875rem;
    margin-bottom: 0.875rem;
    border: $border-width $border-style $border-filter-color;
    border-radius: $border-radius;
  }
}

//DateInterva-InputField
.DateIntervalInputField {
  .control-label {
    width: 100%;
  }

  label {
    display: inline-block;
    //margin-bottom: 0.5rem;
  }

  .input-group {
    width: 100%;
    margin-bottom: 1rem;
    //media queries
    @include mq('tablet-medium') {
      width: 50%;
      float: left;
      margin-bottom: 0;
    }
  }
}

.score::before {
  content: "-";
  color: #9ca2a8;
  font-size: 1.6rem;
}

.form-control-date-picker {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//dentro de modal
.into {
  &.DateIntervalInputField {
    .input-group {
      //media queries
      @include mq('tablet-medium') {
        width: auto;
      }
    }
  }
}


// boton borde redoneado
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.input-double {
  .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }
}


//icono en inputs
.input-group-prepend {
  .input-group-text {
    background-color: $bg-white;
  }
}

.input-group-append {
  &.separate-left {
    margin-left: 0.625rem;
  }
}

//mensajes de ayuda y error bajo inputs
small {
  &.help-block {
    min-height: $height-message;
    display: block;
    padding-top: 2px;
    font-family: $font-base;
    font-size: 0.7rem;
    font-weight: 400;
    color: #000;
  }

  &.js-error {
    @extend .help-block;
    color: $font-color-red;
  }
}

//dentro de tabla
table {
  small {
    &.help-block {
      min-height: 0;
      padding: 0;
      margin: 0;
    }

    &.js-error {
      @extend .help-block;
    }
  }
}

//anchura maxima de input o select, cuando no quiere que se ocupe el ancho de col
.max-width-input {
  max-width: 500px;
}

//mensaje bajo input - js-info -
.js-info {
  //min-height: 15px;
  font-family: $font-base;
  font-size: 0.75rem;
  line-height: $line-height;
}

//calendario e input dentro de tabla - hacemos que no haga wrap -
.table, .fix-form {
  .form-group {
    &.DateInputField {
      min-width: 136px;

      label {
        &.control-label {
          @include hide();
        }
      }

      .input-group {
        @include hide();

        + .react-datepicker-wrapper {
          position: relative;

          &::before {
            @include hide();
            font-family: "Font Awesome 5 Pro";
            content: '\f073';
            font-weight: 900;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0.375rem 0.75rem;
            border-right: 1px solid $border-filter-color;
            pointer-events: none;
            //media queries
            @include mq('desktop') {
              @include show();
            }
          }

          input.form-control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background: pink;
            //media queries
            @include mq('desktop') {
              text-indent: 1.8rem;
            }
          }
        }
      }
    }
  }
}

//cabecera icono + titulo

.card-title .fa,
.card-title .fas {
  color: #B8B8B8;
  font-weight: 900;
  margin-right: 14px;
}

//ángulos text click
.text-click {
  .fas,
  .fa,
  .far {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.25rem;
    font-size: 1rem;
  }
}

.d-flex {
  > .text-click {
    position: relative;
    //top: 6px;
  }
}

.fa-directions {
  color: #A3A3A3;
  font-size: 25px;
  padding: 5px;
}

.icon-directions-background:hover {
  background-color: #A3A3A3;
  cursor: pointer;
  border-radius: 5px;

  .fa-directions {
    color: #fff;
  }
}


// labels de formulario diferentes colores

.control-label,
.col-form-label {
  //color: #000;
  color: $color-gray-light;
  font-size: 0.8rem;

  &--grey {
    color: $color-gray-light;
  }
}

.input-group-remove {
  position: absolute;
  right: 60px;
  top: 3px;
  z-index: 3;
  height: 32px;

  .close {
    &:focus {
      outline: transparent;
    }
  }

  .fas {
    font-size: 15px;
    color: #6c757d;
  }
}


// inputs con estilos especial : input search + icono

.input-group {
  &--search {
    .form-group {
      margin-bottom: 0;
      height: 38px;
    }
  }

  &--calendar {
    .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 38px;
    }

    //seccion icono calendario
    .input-group-text {
      height: 38px;
      // calendario
      .fas {
        color: $color-asc-desc;
      }

    }
  }
}

.input-group-text {
  .fas, .fa {
    color: $color-asc-desc;
    margin: 0;
  }
}

.calendar--wrapper {
  .text-click {
    position: relative;
  }

  .form-group {
    margin-bottom: 0;
  }

  //flechas
  .fa-caret-left {
    position: absolute;
    top: 36%;
    transform: translateY(-50%);
    left: -15px;
  }

  .fa-caret-right {
    position: absolute;
    top: 36%;
    transform: translateY(-50%);
  }
}

//calendario iconos felchas
.schedule-calendar {
  .text-click {
    .fas {
      color: $color-gray-medium;
      position: absolute;
      top: 36%;
      transform: translateY(-50%);

      &.fa-caret-left {
        left: -15px;
      }

      &.fa-caret-right {

      }
    }
  }
}

//input y select con menor alto

.inputheight-s {
  //input propio
  .form-control {
    padding: 0.21rem 0.75rem !important;
  }

  //input autosuggest
  .react-autosuggest__input {
    height: 28px;
  }
}


//unificar focus
input.form-control:focus,
input.custom-select:focus,
select.form-control:focus,
select.custom-select:focus,
textarea.form-control:focus,
textarea.custom-select:focus,
.select-control.form-control:focus,
.select-control.custom-select:focus {
  box-shadow: 0 0 0 2px #bfdeff !important;
  border-color: #2684FF !important;
  outline: none;
}

//input calendario quitar outline negro
input.mbsc-overwrite:focus {
  outline: none;
}

.bg-pink {
  background-color: #FFE5E5;
}

.toast-fixed {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  position: absolute;

  .toast {
    border-radius: 50px;
    min-width: 600px;
    min-height: 65px;
    z-index: 99;
    margin: auto;
    background-color: yellow;
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .toast-div {
      text-align: center;
    }

    button {
      position: absolute;
      right: 0;
      top: 20px;
    }

    &.bg-success {
      background-color: rgba(85, 162, 217, 0.85) !important;
    }

    .toast-fixed {
      //media queries
      @include mq('tablet', 'max') {
        position: fixed;
        top: 69px;
        right: 15px;
      }
      // tiene estilo absolute por defecto
      & > div {
        @include mq('tablet', 'max') {
          position: static !important;
          top: 0 !important;
          right: 0 !important;
          min-width: auto !important;
        }
      }
    }
  }
}

/* worklogs */
.worklog-div{
  &.editing{
    .worklog-resume{
      background: #FaFaFa;
      border-top: 1px solid #6C757D;
      padding: 0.5rem 0.5rem 0 0.5rem;
      border-bottom: 1px solid rgba(108, 117, 125, 0.47);
      margin-bottom: 0.5rem;
    }
  }
}
