.web-header{
  .header-alerts{
    //con desplegable
    &.dropdown{
      margin-right: calc(1rem + 5px);
      transition: 0.5s;
      &:hover{
        background: rgba(237, 237, 237, 1);
        border-radius: 5px;
      }
    }
    //button
    .alerts-dropdown {
      position: relative;
      color: #707070;
      height: auto;
      line-height: normal;
      padding: 11px;
      background-color: transparent;
      &.btn--alert{
        border-radius: 100%;
        min-width: 30px;
        min-height: 30px;
        width: 100%;
        padding: 5px;
        outline: 1px solid #cbcbcb;
        &.red--alert {
          border-color: red;
        }
        &.warning--alert {
          border-color: #ffc107;
        }
        &.info--alert {
          border-color: #17a2b8;
        }
      }
    }
    // alerta que flota
    .badge {
      position: absolute;
      font-family: 'Roboto', sans-serif;
      font-size: 0.650rem;
      color: #fff;
      border-radius: 100%;
      top: -2px;
      right: 21px;
      padding: 4px 2px;
      font-style: normal;
      font-weight: normal;
    }
  }
}
