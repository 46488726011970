.user{
    width: 100%;
    padding: 0;
    margin: 0 auto;
    font-size: $font-size-small;
    .user__info{
        width: 100%;
        .user__pic{
          width: 100%;
          max-width: 208px;
          height: 100%;
          max-height: 145px;
          position: relative;
          @include push-auto();
          padding: 0.750em;
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
          border: $color-gray-light;
          border-radius: $border-radius;
            img{
                width: auto;
                max-width: 123px;
                height: auto;
                max-height: 123px;
                display: block;
            }
            .user__pic__edit{
                width: auto;
                max-width: 40px;
                height: auto;
                position: absolute;
                right: 0.750em;
                bottom: 0.750em;
                padding: 0;
                border: 0;
                background: none;
                cursor: pointer;
                .fa,
                .fas,
                .far{
                    width: 100%;
                    height: 100%;
                    display: block;
                    font-size: 2.2em;
                    color: #CED4DA;
                }
                &:hover{
                   opacity: .85;
                }
            }
        }
        //formulario
        .user__form{
            .form-group{
                &.no-event{
                    margin-bottom: 0;
                    input{
                        border: 0;
                    }
                }
            }
            //label
            .control-label{
               margin-bottom: 0.688rem;
               font-family: $font-base;
               font-size: 1em;
               font-weight: 400;
               text-transform: capitalize;
               line-height: $line-height;
               color: $font-color-light;
                &.dark{
                    color: $font-color-xdark;
                }
            }

          label {
            display: inline-block;
            //margin-bottom: 0.5rem;
          }
            //input
            .form-control,
            .custom-select{
                @extend .control-label;
                color: $font-color-xdark;
            }
            .form-control{
                margin: 0;
                padding: 0;
            }
        }
    }
    //cambiar contraseña
    .change-password{
        @extend .user__info;
        max-width: 520px;
        .user__form{
            .form-control{
                height: 2.375rem;
                padding: 0 0.5em;
            }
        }
        //texto explicación
        .passwordNew-explication{
            font-family: $font-base;
            font-size: 0.857em;
            font-weight: 400;
            line-height: $line-height;
            color: $font-color-light;
        }
    }
}
