h3,
.title-tertiary{
    margin: 0;
    font-family: $font-base;
    font-size: 1em;
    font-weight: 600;
    color: $color-title;
    span{
        font-weight: 500;
    }
}
//texto estándar de 14px y roboto
.text-standar,
p.text-standar{
    font-family: $font-base;
    font-size: 0.875em;
    font-weight: 400;
    color: $font-color-dark;
}