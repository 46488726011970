.modal-push{
    //columnas y paddings en modales
    *[class="row"]{
        //margin-left: -8px;
        //margin-right: -8px;
    }
    *[class*="col-"]{
        //padding-left: 8px;
        //padding-right: 8px;
    }
}
.text-warning-imports {
    color: #e51f00;
}
-text-danger{
    color: #bc212c
}
.fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;

    border-top: 1px solid #E7E7E7;
    background: rgba(255,255,255,0.9);
    padding: 0 1rem;
    margin-right: 1rem; //Para no estar encima del scroll
}

.fixed-bottom-space-maker{
    height: 62.8px;
}

.web-body{
    .form-group{
        margin-bottom: $item-mb;
        .form-checkform-check-inline{
            .Select2Component{
                margin-bottom: 0;
            }
        }
        &.Select2Component{
            label[for="schedudeByTime"]
            //label[for="posZoneId"]
            {
                //margin-bottom:0;
            }
        }
    }
}

.web-main{
    .modal-body{
        //padding:  0 .5rem;
    }
}

.form-control{
    padding:0.525rem 0.75rem !important;
}



//MEDIA QUERIES PARA FIREFOX

@-moz-document url-prefix() {
    table{
        //border-collapse: inherit !important;
        //border-collapse: collapse;
        //border-spacing: 1px;
        //&.table.drop-work-unit{
        //    border-collapse: collapse !important;
        //    border-spacing: 0;
        //}
    }

    // tabla calendario
    .table-second .table.drop-work-unit tbody td table, .table-third .table.drop-work-unit tbody td table {
        width: auto;
        height: 152% !important;
        border-collapse: initial;
        border-spacing: 0;
    }

    table.table.drop-work-unit{
        border-collapse: inherit;
        border-spacing: 0;
    }


    .table-second .table tbody th, .table-third .table tbody th{
        padding:0 0.5em !important;
    }
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(218 ,41 ,28 , 0.1);
}

.table thead.thead-fixed-byTech{
    position: fixed;
    top: 0;
    z-index: 10;
    background: #fff;
}

.table thead.thead-fixed{
    position: fixed;
    top: 82px;
    z-index: 10;
    background: #fff;
}

.table tbody.tbody-fixed{
    position: fixed;
    top: 0;
    z-index: 10;
    background: #EFF0F2;
}

.th-work-load {
    width: 223.95px;
}