pre.sf-dump, pre.sf-dump .sf-dump-default {
    background-color: #18171B;
    color: #FF8400;
    line-height: 1.2em;
    font: 12px Menlo, Monaco, Consolas, monospace;
    word-wrap: break-word;
    white-space: pre-wrap;
    position: relative;
    z-index: 99999;
    word-break: break-all;
}
pre.sf-dump .sf-dump-key {
    color: #56DB3A;
}
pre.sf-dump span {
    display: inline;
}
pre.sf-dump .sf-dump-ref {
    color: #A0A0A0;
}
pre.sf-dump .sf-dump-str {
    font-weight: bold;
    color: #56DB3A;
}
pre.sf-dump .sf-dump-num {
    font-weight: bold;
    color: #1299DA;
}
pre.sf-dump .sf-dump-function {
    font-weight: bold;
    color: #1299DA;
}
pre.sf-dump .sf-dump-boolean {
    font-weight: bold;
    color: #1299DA;
}
pre.sf-dump .sf-dump-note {
    color: #1299DA;
}
pre.sf-dump .sf-dump-list {
    list-style-type: none;
    padding-left: 12px;
}
pre.sf-dump {

}
