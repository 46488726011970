

.c-codeqr{
  display: flex;
  align-items:center;
  flex-direction: column;
  margin: 0 8%;
  max-width: 340px;
  width: 100%;
  //titulo
  &__title{
    font-family: $font-base;
    font-size:12px;
    color:$color-gray-light;
    line-height: 23px;
    margin-bottom: 5px;
    text-align: center;
  }
  //imagen google o app
  &__img{
    max-width:140px;
    margin-bottom: 10px;
  }
  //img code qr
  &__qr{
    max-width:90px;
  }
  // grupo de codigos qr
  &--group{
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    //justify-content: space-evenly;
  }
}