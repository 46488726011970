footer.web-footer,
.web-footer{
    margin-left: 0;
    height: 1.875rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 97;
    background-color: $color-footer;
    color: $font-color-white;
    .footer-poweredby{
        width: 230px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow-x: hidden;
        transition: all 300ms ease;
        background: #000;
        font-size: 0.7rem;
        img{
            margin-left: 5px;
        }
    }
    .header-toggler{
        display: inline-flex;
        align-items: center;
        background: transparent;
        border: 0;
        color: #fff;
        margin: 0 1rem;
        cursor: pointer;
    }
    .sidebar-toggler{
        @extend .header-toggler;
    }
    .footer-nav{
        display: inline-flex;
        align-items: center;
        ul{
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                margin: 0 1rem;
                font-size: 0.875rem;
                a{
                    font-size: 0.75rem;
                    color: $font-color-white;
                }
            }
        }
    }
}

.sidebar-minimized{
    footer.web-footer,
    .web-footer{
        //width: calc(100% - 56px);
        //margin-left: 56px;
    }
}
