//border radius
.u-border-radius{
    border-radius: $border-radius !important;
}

//estilos que se habían agregado en archivo variables.scss, se pasan aquí
.border-color-green{
    outline: 3px solid #28a745!important;
    outline-offset: -3px;
}
.border-color-blue{
    outline: 3px solid $color-blue!important;
    outline-offset: -3px;
}
.color-text-green{
    color: $color-green-light!important;
}
.color-text-finished{
    color: $color-finished!important;
}
.color-text-paused{
    color: $color-paused!important;
}
.color-text-returned{
    color: $color-returned!important;
}
.color-text-inProgress{
    color: $color-inprocess!important;
}
.color-text-blue{
    color: $color-blue!important;
}

.seleccionada{
    outline: 3px solid #d39e00!important;
    outline-offset: -3px;
}

// para que en el pop up quede por enicma de los iconos y texto
.react-datepicker-popper {
    z-index: 11 !important;
}

// util creado para espacio blanco sobrante que salia despues del footer
.u-overflow-hidden{
    overflow:hidden;
}

@-webkit-keyframes scale-up-right {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
    }
}
@keyframes scale-up-right {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
    }
    100% {
        -webkit-transform: scale(1) translateX(-50%);
        transform: scale(1)  translateX(-50%);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
    }
}