//CALENDARIOS - hay que tener en cuenta todos los estilos que tienen en línea del propio componente

.DayPicker {
  //contenedor de todos los bloques de meses
  &-Months {
  }

  //cada bloque de mes
  &-Month {
    max-width: 260px;
  }

  //mes y año
  &-Caption {
    margin-top: 1rem;
    font-family: $font-base;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;
    color: $font-color-orange;

    div {
      text-align: center;
    }
  }

  //día de la semana
  &-Weekday {
    font-family: $font-base;
    font-size: 0.75rem;
    font-weight: 400;
    text-align: center;
    color: #000;
  }

  //días - números
  &-Day {
    font-family: $font-third;
    font-size: 0.7rem;
    font-weight: 400;
    text-align: center;
    color: #000;
    border-radius: 0;

    &--selected {

    }

    &--festivo {

    }

    &--localfestivo {

    }

    &--outside {
      background-color: white !important
    }

    //hover
    &:hover {
      font-weight: 600;
    }
  }

  //nav bar
  &-NavBar {
    font-family: $font-base;
    font-size: 0.9rem;
    font-weight: 500;
    color: $font-color-dark;
    //media queries
    @include mq('tablet') {
      position: absolute;
      top: -37px;
      left: 100px;
    }
  }
}

//extends
.DayPicker-Month .DayPicker-Week .DayPicker-Day {
  @extend .DayPicker-Day;
}

