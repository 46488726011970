//SPINNER QUE ROTA
.c-spinner-rotate{
    width: 28px;
    height: 27px;
    position: relative;
    display: inline-block;
    top: -3px;
    margin-right: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    background-image: url('/../img/spinner-rotate.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    transition: transform 0.3s ease;
    cursor: pointer;
    &:hover{
        background-position: 0 -24px;
    }
    &.focus{
        background-position: 0 -49px;
        transform: rotate(360deg);
        outline: 0;
    }
    span{
        @include hide();
    }
    + div{
        margin-left: 1rem;
    }
    //media queries
    @include mq('tablet-medium'){
        top: 0;
        + div{
            margin-left: 1.1rem;
            margin-bottom: 0.2rem;
        }

    }

}


