.web-header{
  &__left{

  }
  &__right{
    //.mt-tenant-selector{
    //  margin-top:0 !important;
    //}
    //.form-group{
    //  margin-bottom: 0;
    //}
    //.control-label{
    //  height:1px;
    //}
  }
}

//seccion header de cada seccion
.title-section{
  //switch
  .c-switch{
    .form-group{
      margin-bottom: 0;
    }
  }
}