//Mensaje de texto informativo que va bajo inputs (tiene estilos en línea que pueden pisar a algunos de los que hay en este archivo)

.js-info {
  margin-top: 0.25rem;
  font-family: $font-base;
  font-size: 0.750rem;
  font-weight: 400;
  color: $font-color-light;
}

.js-info-related {
  font-family: $font-base;
  font-size: 0.750rem;
  font-weight: 400;
  color: $font-color-blue-dark;

  &.admin {
    margin-left: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    color: $font-color;
  }
}

.qr-center {
  text-align: center;
  margin-bottom: 1.2rem;
}
