//BLQOUE VISITAS: esencial, complementaria y tipo A
.visits {
  @include flexbox();
  @include flex-wrap(wrap);
  padding: 0.375em 0.750em; //6px y 12px
  font-size: $font-size-small;
  //media queries
  @include mq('tablet') {
    font-size: $font-size;
  }

  .visits__text {
    width: calc(100% - 150px);
    min-width: 95px;
    margin: 0;
    font-family: $font-base;
    font-size: 0.750em; //12px
    font-weight: 400;
    text-transform: capitalize;
    color: $font-color-dark;
  }

  .right-drag-hours {
    width: 146px;
    @include flexbox();
    @include flex-wrap(wrap);
  }
}

.border-visit {
  border: 1px solid $color-drag-item;
}
