.logger-block{
    font-family: "Lucida Console", Monaco, monospace;
    font-size: 11px;
    .query,
    .variables,
    .logger {
        display: flex;
        margin-bottom: 5px;

        > span {
            width: 80px;
            flex-shrink: 0;
            color: #888;
        }
    }

    .query,
    .variables{
        > div {
            word-break: break-all;
            line-height: 1.7;
        }
    }

    .logger{
        .logger-item{
            clear: left;
            margin-bottom: 5px;

            .time,
            .type,
            .ellapsed-ms{
                float: left;
                margin-right: 15px;
            }

            .time,
            .ellapsed-ms{
                color: #888;
            }

            .type{
                padding: 0rem 0.2rem;
                color: #383d41;
                background-color: #e2e3e5;
                border: 1px solid #d6d8db;

                &.DBG {
                    color: #383d41;
                    background-color: #e2e3e5;
                    border-color: #d6d8db;
                }
                &.INF {
                    color: #0c5460;
                    background-color: #d1ecf1;
                    border-color: #bee5eb;
                }
                &.VSE {
                    color: #856404;
                    background-color: #fff3cd;
                    border-color: #ffeeba;
                }
                &.FTL {
                    color: #721c24;
                    background-color: #f8d7da;
                    border-color: #f5c6cb;
                }
            }

            .message{
                word-break: break-all;
                line-height: 1.7;
                border-top-color: #b8c3c6;
                border-top-width: 1px;
                border-top-style: solid;
            }
        }
    }
    .variableInputType {
        color: #a70606;
    }
    .variableDollar {
        color: #38923b;
        font-weight: bold;
    }
    .variableJs {
        color: #ab6c27;
    }
    .tablename {
        font-weight: bold;
        background-color: #98efde;
    }
    .gqlParentChild {
        font-weight: bold;
        color: #782086;
    }
    .gqlParent {
        font-weight: bold;
        color: #782086;
    }
    .sql {
        color: #cf2b2b;
    }
}