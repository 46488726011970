/**cuando sidebar está minimizado se muestra el nombre de cada sección**/
.sidebar-minimized{
    .nav-item__name,
    .nav-item--dropdown{
        @include translate(-220px, 0);
        @include transition( all 0.25s ease);
    }
    .nav-item__name{
        display: block;
    }
    .nav-item{
        &:hover{
            .nav-item__name{
                width: auto;
                min-width: 100px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                padding: 0 0.5rem;
                border-left: 1px solid #fff;
                font-weight: 400;
                color: $font-color-white;
                background-color: #35485C;
                @include translate(56px, 0);
                &.master{
                    min-width: 220px;
                }
            }
            .nav-item--dropdown{
                @extend .nav-item__name;
                min-width: 220px;
                top: 52px;
                padding: 0;
                .nav-item--dropdown__item{
                    a{
                        padding-left: 0.6rem;
                        padding-right: 0.6rem;
                    }

                }
            }
        }
    }
    //ocultamos iconos ángulos
    .fa-angle-up,
    .fa-angle-left{
        @include hide();
    }
}
