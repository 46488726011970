//tíutlo de elemento select2

.o-title-select{
    margin-bottom: 0.6rem;
    font-family: $font-base;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: $line-height;
    color: $font-color-light;
}

