.app-page,
.page-g {
  //textos
  p, h3 {
    font-size: 12px;
    font-family: $font-base;
    color: $color-gray-light;
    line-height: 23px;
    margin-bottom: 0.3rem;
    font-weight: normal;
  }

  //listas
  ul {
    list-style: none;
    padding: 0;

    li {
      font-size: 12px;
      font-family: $font-base;
      color: $color-gray-light;
      line-height: 23px;
      margin-bottom: 0.3rem;
    }
  }
}

.calendar-filter {
  display: flex;
  @include mq('desktop-wide', 'max') {
    flex-wrap: wrap;
  }

}

.col-calendar {
  flex: 1;
  margin-right: 1rem;
  @include mq('desktop-wide', 'max') {
    flex: 0 0 35%;
  }
  @include mq('tablet', 'max') {
    flex: 0 0 40%;
  }
}

.calendar-w {
  max-width: 145px
}

.calendar-q-w {
  max-width: 170px

}