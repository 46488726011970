//VARIABLES

//breakpoints
$breakpoints: (
        "phone":        355px,
        "phone-wide":   480px,
        "phablet":      560px,
        "tablet-small": 640px,
        "tablet":       768px,
        "tablet-medium":992px,
        "tablet-wide":  1024px,
        "desktop":      1248px,
        "desktop-wide": 1440px
);

//referencia función pasar px a em
$browser-context: 16;

//colores genéricos
$bg-white: rgba(255, 255, 255, 1);
$bg-title: #F6F6F6;
$bg-item-active: #eee;
$color-blue: #007BFF;
$color-blue-medium: #0058F5;
$color-blue-dark: #3D708D;
$color-red: #E51227;
$color-red-light: #E70C21;
$color-red-medium: #CA3432;
$color-red-dark: #BC212C;
$color-red-extra: #650200;
$color-gray: #3E3E3E;
$color-gray-dark: #2D3B41;
$color-gray-medium: #576267;
$color-gray-light: #6C757D;
$color-xdark: #212529;
$color-green: #4B713D;
$color-green-light: #28A745;
$color-orange: #E1A23F;
$color-drag-item: #A2BFD0;
$color-border: #D3D3D3;
$color-title: #707070;
$color-deep-red: #721C24;
$color-dark-yellow: #DAA53D;
$color-asc-desc: #B3B3B3;
$color-disabled: #E9ECEF;
$color-footer: #6E757D;
$color-success: #28A745;
$color-attention: #FFD869;
$color-flamboyant-orange: #FF4633;

//status
$color-inprocess: #007BFF;
$color-paused: #B3B3B3;
$color-returned: #666;
$color-finished: #28A745;

//tipografía
$font-base: 'Roboto', sans-serif;
$font-secondary: 'Raleway', sans-serif;
$font-third: arial, helvetica, sans-serif;
$font-size: 1rem;
$font-size-small: 0.85rem;
$font-size-xsmall: 0.75rem;

//line height textos
$line-height: 1.5;

//colores fuente
$font-color: #2D3B41;
$font-color-gray: $color-gray;
$font-color-dark: $color-gray-dark;
$font-color-medium: $color-gray-medium;
$font-color-light: $color-gray-light;
$font-color-xdark: $color-xdark;
$font-color-blue-dark: $color-blue-dark;
$font-color-white: #fff;
$font-color-red: $color-red;
$font-color-red-light: $color-red-light;
$font-color-red-dark: $color-red-dark;
$font-color-orange: $color-orange;
$font-color-disabled: #CED4DA;
$title-form-color: #707070;
$font-color-footer: $font-color-dark;

//bordes
$border-width: 1px;
$border-style: solid;
$border-radius: 4px;
$border-radio-color: #b2b2b2;
$border-list-color: #95989A;
$border-filter-color: #CED4DA;

//Botones
$button-height: 1.875rem;
$button-height-x:2.375rem;
$bg-button: $bg-white;
$bg-button-red: $color-red-dark;
$bg-button-orange: $color-flamboyant-orange;
$bg-button-grey: $color-gray-medium;
$button-color: $font-color-red;

//box shadow
$box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.2);

//drags ítems
$drag-width: 80px;
$drag-height: 33px;

//anchura celdas
$cell-width: 185px;
$cell-height: $drag-height;
$height-bottom: 35px;

//altura search
$search-height: 2.375rem;

//mensajes alerta
$bg-alert: #f8d7da;
$alert-text: $color-deep-red;

//altura mínima mensajes de ayuda y error
$height-message: 20px;

//margen inferior elementos de formularios
$item-mb: 1.2rem;

//modal
$max-width-modal: 800px;

//focus
$focusDark: 1px dotted $color-xdark;
$focusLight: 1px dotted #fff;

//Colores tooltip
$color-prevent: #A2BFD0;
$color-program: #B197DC;
$color-finished: #a5dcab;
$color-corrective: #EA9595;
$color-events: #FFBF8A;

//Formularios
$heightElementForm: 2.375rem;
