//INFO COLORES SOBRE TABLA
.color-info-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    @include hide();
    .color-info-wrapper__item{
        margin-right: 0.857em;
        &:last-child{
            margin-right: 0;
        }
        //cuadrado color
        .color-info{
            width: 10px;
            height: 10px;
            display: inline-block;
            &.urgent{
                background-color: #FF0000;
            }
            &.preventive{
                background-color: #A2BFD0;
            }
            &.corrective{
                background-color: #F99494;
            }
            &.tec-colaborate{
                background-color: #A2BFD0;
            }
            &.holidays{
                background-color: #E2D1D1;
            }
            &.other-events{
                background-color: #DAA53D;
            }
        }
        //texto
        .color-info-text{
            display: inline-block;
            margin-left: 0.571em; //8px
            font-family: $font-base;
            font-size: 1em; //14px
            font-weight: 400;
            line-height: 1.5;
            text-align: left;
            text-transform: capitalize;
            color: $font-color-dark;
        }
    }
}
.to-transform-none {
    text-transform: none !important;
}

//TABLAS
table{
    //icono wifi
    .fa-wifi{
        display: inline-block;
        margin-right: 0.25rem;
    }
}
.table{
    /*Añadido IBR prueba test2 para tabla de planificacion*/
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    thead,
    .thead{
        th{
            a{
                text-decoration: none;
            }
            .fas,
            .fa,
            .far{
                display: inline-block;
                margin-left: 0.2rem;
                font-size: inherit;
                color: $color-asc-desc;
            }
        }
    }
    td{
        .form-group{
            margin-bottom: 0;
        }
    }
    //tabla layout fixed
    &--fixed{
        table-layout: fixed;
    }
    //tabla interna
    &.label-days{
        td{
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }
    }
}

//TABLA: condiciones de órdenes de trabajo preventivas
.table-four{
    font-size: $font-size;
    .table{
        overflow: auto;
        thead{
            height: 40px;
            border-bottom: 1px solid $color-gray-dark;
            th{
                //width: 90px;
                vertical-align: bottom;
                position: relative;
                padding: 0.2rem 0.4rem;
                font-family: $font-base;
                font-size: 0.688em; //11px
                font-weight: 400;
                text-transform: capitalize;
                text-align: left;
                color: $font-color-medium;
                background-color: transparent;
                border-top: 0;
                border-bottom: 1px solid #D3D3D3;
                &::before{
                    content: '';
                    width: 1px;
                    height: 14px;
                    position: absolute;
                    bottom: 2px;
                    left: 0;
                    background-color: #707070;
                }
                &:last-child::after{
                    content: '';
                    width: 2px;
                    height: 14px;
                    position: absolute;
                    bottom: 2px;
                    right: 0;
                    background-color: #707070;
                }
                .year{
                    font-size: 0.818em; //9px
                    color: #b3b3b3;
                }
            }
            //&:last-child::before{
            //    content: '';
            //    width: 1px;
            //    height: 14px;
            //    position: absolute;
            //    bottom: 2px;
            //    right: 0;
            //    background-color: red;
            //}
        }
        tbody{
          tr {
            &.preventivas {
              height: 40px;

            }
          }
            td{
                padding: 0.2em;
                border-bottom: 1px solid $color-border;
            }
        }
    }
    //totales
    &.table-four--small{
        .table{
            thead{
                border-bottom: 0;
                th{
                    border-bottom: 1px solid $color-gray-dark;
                    &::before{
                        display: none;
                    }
                    &.cell-total{
                        text-transform: uppercase;
                        &:first-child{
                            border-right: 5px solid #fff;
                        }
                        &:last-child{
                            border-left: 5px solid #fff;
                        }
                        span{
                            display: block;
                            line-height: 1.1;
                            text-transform: capitalize;
                        }
                    }
                }
            }
            tbody{
                td{
                    border: 0;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    .total{
                        height: 33px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        padding-right: 0.3em;
                        font-family: $font-base;
                        font-size: 0.750em; //12px
                        font-weight: 800;
                        background-color:  $color-drag-item;
                        .small{
                            display: inline-block;
                            position: relative;
                            bottom: 0.5em;
                            font-size: 0.55em;
                        }
                    }
                }
            }
        }
    }
}

//TABLA NÚMERO ORDEN DE TRABAJO
.table-edit{
    &.second{
        th,
        td{
            &.title-cell{
                padding-bottom: 0;
                border-bottom: 0;
                font-family: $font-base;
                font-size: 0.8rem;
                font-weight: 800;
                line-height: $line-height;
                color: $color-blue-dark;
            }
            &.notes-cell{
                //@extend .title-cell;
                font-weight: 400;
                color: #3D708D;
                font-style: italic;
            }
        }
        thead,
        .thead{
            th{
                //border-top: 0;
                font-weight: 600;
            }
        }
        tbody{
            span{
                &.schedule{
                    color: $color-success;
                }

                &.not-schedule{
                    color: $color-red;
                }
            }
            td{
                &.td-underline div{
                    text-decoration: underline;
                }
                .status{
                    font-weight: bold;
                }
            }
        }
    }
}
.no-attached,
.yes-attached{
    display: inline-block;
    vertical-align: middle;
    &::before{
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        margin-right: 0.5rem;
    }
}
.no-attached{
    margin-left: 0.3rem;
    &::before{
        content: '\f057';
        color: $color-gray-dark;
    }
}
.yes-attached{
    margin-right: 0.3rem;
    &::before{
        content: '\f0c6';
        color: #E70C21;
    }
}

//BLOQUE ESTILO TABLA
.block-table{
    .block-table__body{
        box-shadow: $box-shadow;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

//tabla main-list
.table-main-list{
    .table{
        thead,
        tbody{
            th,
            td{
                min-width: 40px;
                vertical-align: middle;
                //media queries
                @include mq('desktop') {
                    white-space: nowrap;
                }
                &.row{
                    margin:0;
                    .form-checkform-check-inline{
                        .form-group input[type='checkbox'] + label:before{
                            top: -6px;
                        }
                    }
                }
                div{
                    div{
                        padding-left: 2px;
                        padding-right: 2px;
                    }
                }

            }
        }
    }
}

//Tabla work unit
.table-second{
    .table{
        &.drop-work-unit{
            width: auto;
            margin-bottom: 0.1rem;
            thead{
                th,
                td{
                    .th-bottom__item{
                        //min-width: 90px;
                    }
                    &.th-bottom__item{
                        vertical-align: middle;
                    }
                }
            }
            tbody{
                th,
                td{
                    border-bottom: 0;
                    .th-bottom__item{
                        // min-width: 90px;
                    }
                    &.th-bottom__item{
                        vertical-align: middle;
                    }
                }
                td{
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 0;
                    table{
                        width: auto;
                        height: 100%;
                        border: 0;
                        box-shadow: none;
                        td{
                            width: auto;
                            height: 100%;
                            border-top: 1px solid #f1f1f1;
                            border-left: 1px solid #f1f1f1;
                            border-right: 1px solid #f1f1f1;
                            border-bottom: 1px solid #f1f1f1;
                        }
                    }
                }
            }
        }
    }
}


.table-second-client{
    .table{
        &.drop-work-unit{
            width: auto;
            margin-bottom: 0.1rem;
            thead{
                th,
                td{
                    .th-bottom__item{
                        //min-width: 90px;
                    }
                    &.th-bottom__item{
                        vertical-align: middle;
                    }

                        table{
                            width: auto;
                            height: 100%;
                            border: 0;
                            box-shadow: none;
                            td{
                                width: auto;
                                height: 100%;
                                border-top: 1px solid #f1f1f1;
                                border-left: 1px solid #f1f1f1;
                                border-right: 1px solid #f1f1f1;
                                border-bottom: 1px solid #f1f1f1;
                            }
                        }
                }
            }
            tbody{
                th,
                td{
                    border-bottom: 0;
                    .th-bottom__item{
                        // min-width: 90px;
                    }
                    &.th-bottom__item{
                        vertical-align: middle;
                    }
                }
                td{
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 0;
                    table{
                        width: auto;
                        height: 100%;
                        border: 0;
                        box-shadow: none;
                        td{
                            width: auto;
                            height: 100%;
                            border-top: 1px solid #f1f1f1;
                            border-left: 1px solid #f1f1f1;
                            border-right: 1px solid #f1f1f1;
                            border-bottom: 1px solid #f1f1f1;
                        }
                    }
                }
            }
        }
    }
}

// tabla en forma de seccion con bordes redondeados
.table-edit--section{
    //border:1px solid $border-filter-color;
    padding:7px 14px;
    border-radius:4px;
    margin-bottom: 20px;
    .table{
        margin-bottom: 0;
        thead,
        .thead{
            th,
            td{
                font-size:0.75rem;
                color: $font-color-light;
                line-height: 14px;
                font-weight: 400;
                border:0;
                padding:0;
                .fa{
                    color: $font-color-red-dark;;
                }
            }
        }
        tbody,
        .tbody{
            tr{
                border:0 !important;
            }
            th,
            td{
                border-bottom: 0 !important;
            }
            td{
                border: 0;
                padding:7px 0 0;
                font-weight: 600;
                font-size:12px;
                .link-underline{
                    font-size:12px;

                }
                .c-modal-info-cell__box{
                    margin-bottom: 0;
                }
                .c-modal-info-cell__orders__item-status{
                    margin-right:2.7rem !important;
                }
                &:last-child{
                    border:0;
                }
            }
        }
    }
    //enlaces en columna
    .links-column{
        display:flex;
        flex-direction: column;
        align-items: flex-end;
        span{
            font-size:12px;
            line-height: 16px;
        }
        .fa{
            color: $font-color-red-dark;;
        }
    }
    .links-row{
        display:flex;
        flex-direction: row;
        align-items: flex-end;
        span{
            font-size:12px;
            line-height: 16px;
        }
    }


}

// tabla en forma de seccion en MODAL
.table-edit--MODAL{

}



//iconos ver y editar
.list-quickview-button,
.list-edit-button{
    width: 20px;
    height: 20px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: $border-radius;
    cursor: pointer;
    .fas,
    .far,
    .fa{
        position: absolute;
        top: 50%;
        left: 50%;

        @include translate(-50%, -50%);
        font-size: 14px;
        //color: $font-color-white;
        &.fa-eye{
            color: #4A575F;
        }
    }
    &:hover{
        opacity: .85;
    }
}
.list-quickview-button{
}
.list-edit-button{
    //background-color: #A2BFD0;
}
.edit{
    @extend .list-edit-button;
     margin-right: 0.2rem;
}
.remove{
    @extend .list-quickview-button;
    margin-right: 0;
    margin-left: 0.2rem;
    background-color: $color-red-dark;
}
.inactive{
   background-color: #E9ECEF;
}
.finSemana {
    background-color: #e1e5e9;
}
.vacaciones {
    background-color: #D5EDD5  ;
}
.guardia {
    background-color: #F8E1E1;
}
.baja {
    background-color:  #D0AEAD ;
}
.festivo {
    background-color: #f3fcff;
}
.festivo-local {
    background-color: #c9b4d0;
}
.noTechList{
    color: $color-red-dark;
    font-weight: bold;
}
.no-cabe-derecha{
    border-right:  5px solid $color-red-dark;
}
.no-cabe-izquierda{
    border-left: 5px solid $color-red-dark;
}
//iconos si y no con texto: si, no
.icon-and-text{
    .icon-and-text__item{
        display: flex;
        align-items: center;
        .icon-and-text__item__text{
            margin-left: 0.4rem;
        }
        .fas,
        .fa,
        .far{
            font-size: 1.2em;
            &.fa-check-circle{
                color: $color-gray-light;
            }
            &.fa-times-circle{
                color: $color-red;
            }
        }

    }
}

//para el spinner
.list-component{
    position: relative;
}

//se le pone un min-heihgt a tabla responsive para que se vea el loader cuando está vacía
.table-responsive{
    &.list-component{
        min-height: 200px;
    }
}

//tabla planificaciones
table{
    &.drop-work-unit{
        .label-days{
            > tr{
                td{
                    padding: 0;
                    border: 0;
                    border-right: $border-width $border-style $color-border;
                    &:last-child{
                        border-right: 0;
                    }
                }
            }
        }
        tr{
            td{
                &.day{
                    padding: 0;
                    border: 0;
                    border-right: $border-width $border-style $color-border;
                    &:last-child{
                        border-right: 0;
                    }
                    .th-bottom{
                        .day{
                            font-size: 0.588rem;
                            padding-left: 2px;
                        }
                    }
                }
            }
        }
    }
}

//celda que tiene un span para dejar espacio como elemento hijo
.item-child{
    width: 30px;
    display: inline-block;
    vertical-align: bottom;
    border-bottom: 1px solid $color-blue;
}

// contenedor tabla

.wrapper-table{
    padding:0 15px;
}


// tabla festivos

table.table-festives{
    tbody{
        td{
            width:50%;
            //fecha
            .form-control{
                //padding: 0.2rem 0.75rem !important;
            }
            //select dia festivo
            //.css-yk16xz-control{
            //    min-height: 27px;
            //    .css-tlfecz-indicatorContainer {
            //        padding: 3px;
            //        .css-w8afj7-Input{
            //            padding:0;
            //        }
            //        &:hover{
            //            padding: 3px;
            //        }
            //
            //    }
            //    .css-1gtu0rj-indicatorContainer:hover{
            //        padding: 3px;
            //    }
            //    .css-17rus17-ValueContainer{
            //        padding:0 8px !important;
            //        &.react-select__control--is-focused{
            //            padding:0 8px !important;
            //        }
            //    }
            //
            //    .react-select__control--is-focused{
            //        min-height: 27px;
            //        max-height: 27px;
            //    }
            //}
            //.css-180o2w0-control{
            //    min-height: 27px;
            //    max-height: 27px;
            //}

            //.react-select__control--is-focused{
            //    min-height: 27px;
            //}
        }
    }
}


.festives{
    .card-body{
         .header-sticky{
            position: sticky;
            top: 0;
            z-index: 10;
            background: white;
            .col-12{
                + .content-button{
                    position: absolute;
                    right: 37px;
                    top: 22px;
                }
            }
        }
    }
}


// Tabla td cantidad
.td-quantity{
    width: 78px;
}

.td-code{
    width: 170px;
}

.warning {
    color: $color-dark-yellow;
}
