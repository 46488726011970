
.drop-work-unit {
  /*
  td {
    border: 1px solid black;
    // height:30px;
    // width:30px;
  }
  */

  td:first-child {
    border-left: 0;
  }

  td:last-child {
    border-right: 0;
  }

  th:nth-child(1) {
    //width: 100px;
  }

  td.week {
    border-left: 1px solid black;
    border-right: none;
  }


}

.no-active {
  opacity: 0.5;
  cursor: pointer;
}

.drop-work-unit.mes {

}

.drop-work-unit.semana {
  td {
    //width:100px;
  }
}

.drop-work-unit.twodias {
  td {
    //width:360px;
  }
}

.bottom-calendar-view-more {
  width: 100%;
  max-width: 240px;
  background-color: rgba(45, 59, 65, 0.2);
  text-align: center;
  border-radius: 27px;
  padding: 6px;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 2px 4px #707070;
    text-decoration: none;
    transition: all 0.2s;
  }
}

//TABLA: planificación cargas trabajo
.table-second {
  //max-height: 700px;
  .table {
    //min-width: 1260px;
    width: auto;
    box-shadow: $box-shadow;

    thead {
      background: #fff;

      th {
        width: auto;
        //max-width: $cell-width;
        padding: 0 0.571rem;
        border-bottom: 1px solid $color-xdark;
        border-right: 1px solid $color-xdark;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .th-top {
      margin-bottom: 0.857em; //12px
      font-family: $font-secondary;
      font-size: 0.8rem;
      color: $font-color-xdark;
      text-align: left;
    }

    .th-cliente {
      width: 205.2px;
    }

    .th-top-cliente {
      //width: 209px;
    }

    .display-flex-calendar {
      display: flex;
      justify-content: space-between;
    }

    .th-bottom {
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      list-style-type: none;
      //cuando son números de día de la semana
      &.num {

        width: 100%;
        //max-width: $cell-width;
        margin: 0 auto;
        line-height: 23px;

        .fa-angle-left {
          display: unset;
          visibility: visible;
        }

        .th-bottom-client {
          width: 36.9px !important;
          display: flex;
        }

        .th-bottom__item {
          //width: 80px;
          border-right: 1px solid $color-xdark;
          text-align: center;
          margin: 0 auto;

          &:last-child {
            border-right: 0;
          }


          span {
            display: inline-block;
          }
        }
      }

      //cuando es texto
      &.text {
        .th-bottom__item {
          max-width: 110px;
        }
      }
    }

    .th-bottom__item {
      //min-width: 110px;
      font-family: $font-secondary;
      font-size: 0.688rem; //11px
      color: $font-color-xdark;
      max-width: 100% !important;

      .day {
        margin-left: 2px;
        font-size: 0.75rem;
        color: $color-border;
        text-transform: uppercase;
      }
    }

    .day {
      @extend .th-bottom__item;

      b {
        margin-right: 0.2rem;
      }
    }

    tbody {
      th {
        padding: 0.25em 0.5em;
        line-height: 1.3;
        border-bottom: 2px solid $color-drag-item;

        .shop-num {
          display: block;
        }

        .th-bottom {
          .th-bottom__item {
            font-family: $font-base;
            color: $font-color-medium;
          }

        }

        //tabla con ítems que se arrastran
        .table-sublist {
          display: flex;
          justify-content: flex-end;
          padding: 0;
          list-style-type: none;

          li {
            width: 12px;
            height: 33px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-right: 3px;
            background-color: $color-drag-item;

            &:last-child {
              margin-right: 0;
            }

            a {
              text-decoration: none;
              display: block;
              margin-bottom: 0.3em;
              font-size: 0.75em;
              font-weight: 800;
              color: $font-color-dark;
              //h
              .small {
                font-size: 0.65em;
              }
            }
          }
        }
      }

      //celdas no th
      td {
        width: auto;
        //max-width: 80px;
        height: $cell-height;
        padding: 0;
        vertical-align: middle;
        border-left: 1px solid $color-border;
        border-bottom: 1px solid $color-border;

        .th-bottom {
          &.num {
            height: 100%;
            overflow: hidden;

            .th-bottom__item {
              height: 100%;
              border-right: 1px solid $color-border;

              &:last-child {
                border-right: 0;
              }

              .left,
              .right {
                width: calc(50% - 2px);
                height: 100%;
                position: relative;
                display: inline-block;
                margin: 1px;
              }

              .left {
                &::after {
                  content: '';
                  width: 1px;
                  height: 15px;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  background-color: $color-border;
                }
              }

              &.no-work {
                background-color: lighten($color-border, 5%);
                pointer-events: none;
              }
            }
          }
        }
      }

      th {
        &.th-bg {
          max-width: 70px;
          padding-top: 0.8em;
          padding-bottom: 0.8em;
          font-family: $font-base;
          font-size: 1em; //14px
          font-weight: 400;
          color: #fff;
          background-color: $color-gray-dark;
        }
      }
    }
  }

  //con algunos cambios
  &.second {
    .table {
      th {
        .th-bottom {
          &.text {
            .th-bottom__item {
              font-size: 0.88em;
            }
          }

        }
      }

      tbody {
        tr {
          &.no-work {
            td {
              position: relative;

              &::before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                background-color: rgba(0, 0, 0, .5);
                pointer-events: none;
              }
            }
          }
        }

        th {
          vertical-align: middle;
        }
      }
    }
  }
}

//TABLA: horas planificadas
.table-third {
  @extend .table-second;

  .table {
    height: 155px;


    tbody {
      th {
        width: auto;
        //max-width: $cell-width;
        min-height: 135px;
        position: relative;
        padding: 0.857em 0.571em;
        background-color: $color-gray-dark;
        border-top: 0;
        border-bottom: 0;

        .th-top,
        .th-bottom {
          font-family: $font-base;
          font-size: 0.8rem;
          font-weight: 400;
          text-align: left;
          color: $font-color-white;

          span {
            font-size: inherit;
            color: inherit;
          }
        }


        .th-bottom {
          height: $height-bottom;
          line-height: $height-bottom;
          position: absolute;
          right: 0.857em;
          bottom: 0;
          text-align: right;

        }

      }

      td {
        height: 100%;
        padding: 0;

        .th-bottom {
          line-height: $height-bottom;
          position: relative;
          text-align: right;
        }
      }
    }
  }
}

.table-second-client {
  @extend .table-second;
  //max-height: 700px;

  //min-width: 1260px;
  width: auto;

  thead {
    border-top: 1px solid #2D3B41;
    border-bottom: 1px solid #2D3B41;

    th {
      width: auto;
      //max-width: $cell-width;
      padding: 0 0.571rem;
      border-bottom: 1px solid $color-xdark;
      border-right: 1px solid $color-xdark;

      &:last-child {
        border-right: 0;
      }
    }

    td {
      width: auto;
      //max-width: 80px;
      height: $cell-height;
      padding: 0;
      vertical-align: middle;
      border-left: 1px solid $color-border;
      border-bottom: 1px solid $color-border;
    }

    .inactive {
      background-color: #E9ECEF;
    }
  }

  //celdas no th
  td {
    width: auto;
    //max-width: 80px;
    height: $cell-height;
    padding: 0;
    vertical-align: middle;
    border-left: 1px solid $color-border;
    border-bottom: 1px solid $color-border;

    .th-bottom {
      &.num {
        height: 100%;
        overflow: hidden;

        .th-bottom__item {
          height: 100%;
          border-right: 1px solid $color-border;

          &:last-child {
            border-right: 0;
          }

          .left,
          .right {
            width: calc(50% - 2px);
            height: 100%;
            position: relative;
            display: inline-block;
            margin: 1px;
          }

          .left {
            &::after {
              content: '';
              width: 1px;
              height: 15px;
              position: absolute;
              right: 0;
              bottom: 0;
              background-color: $color-border;
            }
          }

          &.no-work {
            background-color: lighten($color-border, 5%);
            pointer-events: none;
          }
        }
      }
    }
  }

  th {
    &.th-bg {
      max-width: 70px;
      padding-top: 0.8em;
      padding-bottom: 0.8em;
      font-family: $font-base;
      font-size: 1em; //14px
      font-weight: 400;
      color: #fff;
      background-color: $color-gray-dark;
    }
  }


  //con algunos cambios
  &.second-client {
    .table {
      th {
        .th-bottom {
          &.text {
            .th-bottom__item {
              font-size: 0.88em;
            }
          }

        }
      }

      tbody {
        tr {
          &.no-work {
            td {
              position: relative;

              &::before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                background-color: rgba(0, 0, 0, .5);
                pointer-events: none;
              }
            }
          }
        }

        th {
          vertical-align: middle;
        }
      }
    }
  }
}


.contenedor-client {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.hour-client {
  line-height: 16px;
  text-align: center;
}

.calc-hour {
  justify-content: center !important;
  margin: 0 auto !important;
  width: 50%;
  background: #A2BFD0;
}

.weekDay {
  border-right: 2px solid rgb(211, 211, 211) !important;
}

.weekDay td.drop-hours:last-child {
  border-right: 0 !important;
}

.weekDay td.drop-hours:first-child {
  border-left: 0 !important;
}

.th-name-tecnico {
  border-right: 1px solid black;
  align-items: center !important;
}

.connection-indicator {
  height: 33px;
  width: 4px;
  margin-right: 5px;
}

.icon-select-capacitation-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 3px;
  width: 20px;
  height: 16px;
  margin-right: 3px;
  text-decoration: none;
}


td.week.with-1-days {
  padding-left: 6px;
  padding-right: 0px;
}

/* Panel lateral */

.rightActions-show {
  position: fixed;
  top: calc(50% - 14px);
  right: 0;
  background: #BC212C;
  color: #fff;
  border: 0;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rightActions-question {
  position: fixed;
  top: calc(45% - 14px);
  right: 0;
  background: #BC212C;
  color: #fff;
  border: 0;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.sidebar-modals {
  .accordion {
    padding: 0.5rem 0;

    .card {
      margin-bottom: 0.3rem;

      .card-header {
        background-color: #90979e;
        color: white;
        padding: 0.74rem 0.5rem;
        border: 0px;
      }
    }
  }


  .toggle-header {
    display: flex;
    justify-content: space-between;

    i {
      padding: 0.2rem;
      font-size: 1rem;
      color: white;
    }
  }

  .c-tooltip {
    width: 40%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.625rem;
    z-index: 4;
    text-align: right;
    //contenido
    &__content {
      opacity: 0;
      pointer-events: none;
      max-width: 400px;
      position: absolute;
      top: 1.6rem;
      right: 0;
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border: 1px solid $color-gray-medium;
      border-radius: $border-radius;
      background-color: $bg-white;
      transition: all 0.3s ease;
      //cada bloque dentro del contenido: preventiva, cancelada,...
      &-item {
        flex: 0 1 45%;
        min-width: 150px;
        padding: 0.3rem 0;
        display: flex;
        align-items: center;

        &-updatedBy {
          padding: 5px;
          align-items: center;
          display: flex;
          position: absolute;
          background-color: #E1E5E9;
          bottom: 52px;
          border: 1px solid #fff;
          border-radius: 50px;
          box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
          left: -12px;
        }

        //texto
        &-text {
          margin: 0;
          font-family: $font-base;
          font-size: 0.75rem;
          font-weight: 500;
          text-align: left;
          text-transform: capitalize;
          color: black;
        }

        //estado
        &-state {
          display: flex;
          align-items: center;
          padding-left: 0;
          //cuando no lleva icono, solo cuadro de color
        }

        //color
        &-color {
          width: 15px;
          height: 15px;
          position: relative;
          margin-right: 0.8rem;
          border: 1px solid #eee;
          border-radius: 3px;
        }

        &-bordercolor {
          width: 15px;
          height: 15px;
          position: relative;
          margin-right: 0.8rem;
          border: 2px solid #eee;
          border-radius: 3px;

          &.finished {
            border-color: green;
          }

          &.proccess {
            border-color: blue;
          }

          &.selected {
            border-color: yellow;
          }
        }
      }
    }


  }

  span {
      margin-bottom: 0.2rem;
    &.title {
      font-family: Roboto;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  p{
    margin-bottom: 0.2rem;
    &.comments {
      font-size: 0.65rem;
      line-height: 12px;
      color: #6c757d;
      font-weight: 400;
    }
  }
}
