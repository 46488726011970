.o-flex-end{
  @include justify-content(flex-end)
}

// centrado al final
.o-flex-end{
  @include flexbox;
  @include justify-content(flex-end);
  @include align-items(center)
}

// centrado al final
.o-flex-center{
  @include flexbox;
  @include justify-content(center);
  @include align-items(center)
}

// centrado al principio
.o-flex-start{
  @include flexbox;
  @include justify-content(flex-start);
  @include align-items(center)
}

// centrado al principio
.o-flex-between{
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(baseline)
}

//textos
.bold{
  font-weight: 700;
}
.medium{
  font-weight: 600;
}
.regular{
  font-weight: 400;
}
.uppercase{
  text-transform: uppercase;
}
.capitalize{
  text-transform: capitalize;
}
.underline{
  text-decoration: underline;
}

.blue{
  color:$color-blue-medium;
}

.o-dmgrey{
  color:$color-gray-light;
}

.o-parraf {
  margin:0 0 15px 0 !important;
}