//Tooltips

//tooltip base que se encuentra en caption de tabla
.c-tooltip{
    width: 40%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.625rem;
    z-index: 4;
    text-align: right;
    //contenido
    &__content{
        opacity: 0;
        pointer-events: none;
        max-width: 400px;
        position: absolute;
        top: 1.6rem;
        right: 0;
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        border: 1px solid $color-gray-medium;
        border-radius: $border-radius;
        background-color: $bg-white;
        transition: all 0.3s ease;
        //cada bloque dentro del contenido: preventiva, cancelada,...
        &-item{
            flex: 0 1 45%;
            min-width: 150px;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            &-updatedBy{
                padding: 5px;
                align-items: center;
                display: flex;
                position: absolute;
                background-color: #E1E5E9;
                bottom: 52px;
                border: 1px solid #fff;
                border-radius: 50px;
                box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
                left: -12px;
            }
            //texto
            &-text{
                margin: 0;
                font-family: $font-base;
                font-size: 0.7rem;
                font-weight: 400;
                text-align: left;
                text-transform: capitalize;
                color: $font-color-medium;
            }
            //estado
            &-state{
                display: flex;
                align-items: center;
                padding-left: 0.5rem;
                //cuando no lleva icono, solo cuadro de color
                &.no-ico{
                    padding-left: 0.2rem;
                }
            }
            //color
            &-color{
                width: 15px;
                height: 15px;
                position: relative;
                margin-left: 0.3rem;
                margin-right: 0.8rem;
                border: 1px solid #eee;
                border-radius: 3px;
                &.user-state-check,
                &.user-state{
                    &::before{
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 900;
                        font-size: 13px;
                        color: $font-color-xdark;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -1.25rem;
                    }
                }
                &.user-state{
                    &::before{
                        content: '\f007';
                    }
                }
                &.user-state-check{
                    &::before{
                        content: '\f4fc';
                    }
                }
                //colores de cuadrados
                &.color-prevent{
                    background-color: $color-prevent;
                }
                &.color-program {
                    background-color: $color-program;
                }
                &.color-corrective{
                    background-color: $color-corrective;
                }
                &.color-events{
                    background-color: $color-events;
                }
                //rayado
                &.color-prevent--lines{
                    background: repeating-linear-gradient(
                        -45deg,
                        $bg-white,
                        $bg-white 3px,
                        $color-prevent 3px,
                        $color-prevent 6px
                    );
                }
                &.color-program--lines{
                    background: repeating-linear-gradient(
                        -45deg,
                        $bg-white,
                        $bg-white 3px,
                        $color-program 3px,
                        $color-program 6px
                    );
                }
                &.color-corrective--lines{
                    background: repeating-linear-gradient(
                        -45deg,
                        $bg-white,
                        $bg-white 3px,
                        $color-corrective 3px,
                        $color-corrective 6px
                    );
                }
                &.color-events--lines{
                    background: repeating-linear-gradient(
                                    -45deg,
                                    $bg-white,
                                    $bg-white 3px,
                                    $color-events 3px,
                                    $color-events 6px
                    );
                }
            }
        }
    }
    //icono
    &__ico{
        padding: 0;
        border: 0;
        outline: 0;
        background-color: transparent;
        font-size: 1.1rem;
        color: #2D3B41;
        .fas,
        .fa,
        .far{
            margin: 0;
            padding: 0;
            font-size: inherit;
            color: inherit;
        }
        &:focus{
            outline: $focusLight;
        }
        &:hover{
            transition: all 0.9s ease;
            + .c-tooltip__content{
                opacity: 1;
                pointer-events: all;
            }
        }
    }


}
