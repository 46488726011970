.alertsticky,
div.alertsticky {
  width: 100%;
  //max-width: 800px;
  height: auto;
  position: fixed;
  top: 20px;
  left: 5;
  z-index: 999;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  border-radius: $border-radius;
  background-color: $bg-alert;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .44);
  //media queries
  @include mq('tablet') {
    //width: calc(100% - 315px); //left + 15px de margen bootstrap
    //height: 46px;
    //left: 300px; //se suma nav, ico hamburguesa y márgenes
  }

  div {
    font-family: $font-base;
    font-size: 0.875em;
    font-weight: 400;
    line-height: $line-height;
    color: $alert-text;
  }
}

.sidebar-minimized {
  .alertsticky {
    //media queries
    @include mq('tablet') {
      //width: calc(100% - 130px);
      //left: 130px;
    }
  }
}

.alert-danger {
  @extend .alertsticky;
}


.warningsticky,
div.warningsticky {
  width: 100%;
  //max-width: 800px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: $border-radius;
  background-color: $bg-alert;
  border: 2px solid;
  //media queries
  @include mq('tablet') {
    //width: calc(100% - 315px); //left + 15px de margen bootstrap
    //height: 46px;
    //left: 300px; //se suma nav, ico hamburguesa y márgenes
  }

  div {
    font-weight: 300;
    line-height: $line-height;
    color: $alert-text;
    padding: 0.5rem;
  }

  .title {
    font-family: 'Raleway', sans-serif;
    font-size: 1.250rem;
    font-weight: 300;
    margin: 0;
  }

}
.show-more{
  color: #777676;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right!important;
}
.sidebar-minimized {
  .warningsticky {
    //media queries
    @include mq('tablet') {
      //width: calc(100% - 130px);
      //left: 130px;
    }
  }
}

.warning-danger {
  @extend .warningsticky;
}
