//cuando no hay contenido: no hay técnicos, ..

.c-empty-content{
    &__text{
        font-family: $font-base;
        font-size: 0.95rem;
        font-weight: 400;
        line-height: $line-height;
    }
}

//extend
.card.card-home p.c-empty-content__text{
    @extend .c-empty-content__text;
}