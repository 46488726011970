//BOTONES

//genéricos
button.btn,
a.btn,
.btn{
    width: auto;
    height: $button-height;
    padding: 0 3.125rem;
    border-width: $border-width;
    border-style: $border-style;
    border-radius: $border-radius;
    font-family: $font-base;
    font-size: 0.875rem;
    line-height: $button-height;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //efecto hover
    &:hover{}
    //botón anchura media
    &.btn--medium{
        max-width: 560px;
    }
    &.btn--large{
        height:$button-height-x;
        min-width: 240px;
        &.with-icon-save{
            position: relative;
            &::before{
                font-family: "Font Awesome 5 Pro";
                content: '\f0c7';
                font-weight: 900;
                height: 100%;
                position: absolute;
                top: 2px;
                left: 66px;
                font-size: 12px;
            }
        }
    }
    //boton largo con ancho auto
    &.btn--auto{
        height:$button-height-x;
        width: auto;
        padding: 0 30px;
        max-width: 100%;
        &.with-icon-plus{
            i{
                font-size:0.7rem;
            }
            &--left{
                position: relative;
                i{
                    position: absolute;
                    left: 5px;
                    top: 12px;
                }
            }
        }
    }
    // maximo 160 px
    &.btn--max{
        @extend .btn--auto;
        padding:0 8px;
        min-width: 160px;
        &.with-icon-plus{
            &--left{
                padding: 0 8px 0 17px;
            }
        }
    }
    //botón full
    &.btn--full{
        max-width: 100%;
    }
    //botón naranja
    &.btn--orange {
        margin: 5px;
        padding: 0 1.125rem;
        background-color: $bg-white;
        color: $bg-button-red;
        border-color:$bg-button-red;
        &:hover {
           // background-color: $bg-button-red;
            //  color: $font-color-white;
        }
    }
    //botón rojo
    &.btn--red{
        background-color: $bg-button-red;
        border-color: $bg-button-red;
        color: $font-color-white;
    }
    &.btn--grey{
        background-color: $bg-white;
        border-color: $bg-button-grey;
        color: $bg-button-grey;
    }
    &.btn--bggrey{
        background-color: #ECECEC;
        border-color:#ECECEC;
        color: #C4C4C4;
    }
    //botón fondo blanco
    &.btn--white{
        background-color: $bg-white;
        border-color: $color-gray-light;
        color: $font-color;
    }
    //deshabilitado - disabled -
    &.btn--disabled{
        pointer-events: none;
        background-color: $color-disabled;
        border-color: #CED4DA;
        color: $font-color-disabled;
        .fas,
        .fa,
        .far{
            display: inline-block;
            margin-right: 0.25rem;
        }
    }
    //botón con spinner load
    &.btn--loader{
        border-color: $bg-button-red;
        background-color: $bg-white;
        color: $font-color-red-dark;
        .fas,
        .fa,
        .far{
            display: inline-block;
            margin-right: 0.25rem;
        }
        //efecto hover
        &:hover{
            //border-color: $bg-button-red;
            // background-color: $bg-button-red;
            // color: $bg-white;
        }
        &-circle{
            width:50px;
            height:50px;
            border-radius: 100%;
            padding:0;
            i.far{
                margin:0;
                font-size:17px;
            }
        }
    }
    &.btn--orders{
        @extend .btn--loader;
        height: 2.2rem;
        font-weight:600;
    }
    &.btn--loading{
        @extend .btn--red;
        .fas,
        .fa,
        .far{
            display: inline-block;
            margin-right: 0.25rem;
        }
    }
    &.btn--loaded{
        @extend .btn--loading;
        pointer-events: none;
    }

    //botón 'crear nuevo'
    &.btn--new{
        //max-width: 131px;
        height: 2.500rem;
        padding: 25px 10px;
        display: flex;
        align-items: center;
        line-height: 2.500rem;
        background-color: $bg-white;
        font-size: 0.750rem;
        font-weight: 500;
        color: #576267;
        transition: 0.5s;
        &::after{
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            width: 5px;
            height: 5px;
            background: rgba(210, 210, 210, 0.5);
            opacity: 0;
            border-radius: 100%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
        }
        &:focus:not(:active)::after {
            animation: ripple 1s ease-out;
        }
        &:hover{
            background: rgba(237, 237, 237, 1);
            border-radius: 5px;
        }
        &:focus{
            box-shadow: none;
        }
        @keyframes ripple {
            0% {
                transform: scale(0, 0);
                opacity: 1;
            }
            20% {
                transform: scale(20, 12);
                opacity: 1;
            }
            100% {
                opacity: 0;
                transform: scale(25, 14);
            }
        }
    }


    &.btn--user{
        padding: 25px 6px 25px 6px;
        position: relative;
        background-color: $bg-white;
        transition: 0.5s;
        overflow: hidden;
        &::after{
            z-index: 99;
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            width: 5px;
            height: 5px;
            background: rgba(210, 210, 210, 0.5);
            opacity: 0;
            border-radius: 100%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
        }
        &:focus{
            box-shadow: none;
        }
        &:focus:not(:active)::after {
            animation: rippleUser 1s ease-out;
        }
        &:hover{
            background: rgba(237, 237, 237, 1);
            border-radius: 5px;
        }

        @keyframes rippleUser {
            0% {
                transform: scale(0, 0);
                opacity: 1;
            }
            20% {
                transform: scale(25, 15);
                opacity: 1;
            }
            100% {
                opacity: 0;
                transform: scale(40, 25);
            }
        }
    }

    &.btn--conf{
        padding: 25px 0 25px 6px;
        background-color: $bg-white;
        margin-right: 0;
        &:hover{
            background: rgba(237, 237, 237, 1);
            border-radius: 5px;
            .cog-main {
                transform: rotate(20deg);
            }
            .cog-top, .cog-bottom {
                transform: rotate(-20deg);
            }
        }
    }

    &.btn--alert{
        padding: 25px 0 25px 10px;
        background-color: $bg-white;
        margin-right: 0;
        &:hover{
            background: rgba(237, 237, 237, 1);
            border-radius: 5px;
            .cog-main {
                transform: rotate(20deg);
            }
            .cog-top, .cog-bottom {
                transform: rotate(-20deg);
            }
        }
    }


    //botón crear nuevo con icono +
    &.btn--new-task{
        background-color: $bg-white;
        border-color: $bg-button-red;
        color: $font-color-red-dark;
        &::before{
            font-family: 'Font Awesome 5 Pro';
            content: '\f0fe';
            font-weight: 900;
            display: inline-block;
            margin-right: 0.5rem;
        }
    }
    //botón que abre modal (info fontawesome)
    &.btn--openModal{
        width: 0.875rem;
        max-width: 100%;
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        margin-left: 0.2rem;
        border: 0;
        outline: 0;
        background-color: transparent;
        cursor: pointer;
        .fa,
        .fas,
        .far{
            padding: 0;
            font-size: 1.1em;
        }
    }
    //botón que cierra modal
    &.btn--closeModal{
        @extend .btn--red;
        width: 10.000rem;
        max-width: 100%;
        background-color: $bg-button;
        border-color: $color-dark-yellow;
        color: $color-dark-yellow;
        &::before{
            @include hide();
        }
        &:hover{
            color: $font-color-white;
            background-color: $color-dark-yellow;
        }
    }
    //botones que van juntos: abrir filtros, descargar,...
    &.btn-outline-secondary{
        height: 38px;
        //height: auto;
        padding: 0.375rem 0.75rem; //padding de bootstrap
        line-height: $line-height;
        color: #CED4DA;
        background-color: transparent;
        background-image: none;
        border-color: #CED4DA;
    }
    //botones que van juntos: abrir filtros, descargar,...
    &.btn-outline-secondary-so{
        height: 31px;
        //height: auto;
        padding: 0.375rem 0.75rem; //padding de bootstrap
        line-height: $line-height;
        color: #CED4DA;
        background-color: transparent;
        background-image: none;
        border-color: #CED4DA;
    }
    //botón ellipsis: despliega una lista
    &.btn--points{
        width: auto;
        max-width: 100%;
        height: auto;
        padding: 0.05rem 0.875rem;
        font-size: 1.125rem;
        color: #F92C20;
        background-color: transparent;
        border-radius: $border-radius;
        border: 0;
        line-height: 1.4;
        //media queries
        .fa,
        .fas,
        .far{
            font-size: inherit;
            color: inherit;
        }
        &:hover{
        }
        &:focus{
            outline: 0;
        }
    }
    //botón que va con input o elemento de formulario
    &.btn--append{
        @extend .btn--points;
        margin-left: 0.625rem;
        padding: 0 0.938rem;
        border: 1px solid $color-red-medium;
        border-radius: $border-radius;
        height: $button-height-x;
        margin-left: 0;
        &:focus{
            background-color: $color-red-dark;
            color: $font-color-white;
        }
        //media queries
        @include mq('tablet-medium'){
            position: static;
        }
    }
    //botón que es una flecha
    &--arrow{
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
        font-size: 3.125rem;
        font-weight: 800;
        color: $font-color-red-light;
        border: 0;
        background-color: transparent;
        transition: transform 0.2s ease;
        .fas,
        .fa,
        .far{
            font-size: inherit;
            font-weight: inherit;
            color: inherit;
        }
        &:hover{
            opacity: 0.85;
            transform: translateX(2px);
        }
        &:focus{
            outline: $focusDark;
            box-shadow: none;
        }
    }
    // borde gris
    &--gray{
        border-color: $border-filter-color;
        color:$color-asc-desc;
        background: transparent;
        height: 2.375rem;
        &-selected{
            color:$color-red-extra;
            background: #DCDCDC;
            border-color: $border-filter-color;
            height: 2.375rem;
        }
        &:hover{
            color:$color-red-extra;
        }
    }
    // boton estrecho con poco padding
    &.btn--small{
        padding:0 10px;
        .fas,
        .fa,
        .far{
            display: inline-block;
            margin-right: 0.25rem;
        }
    }
    &.close{
        padding: 0;
        border: 0;
    }
    &.btn-span{
        border: 1px solid #D0D7DB;
    }
    &.btn-block {
        width: 100%;
    }
}

.button-form-group {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    //media queries
    @include mq('tablet-medium'){
        margin-top: 2rem;
    }
    &.into-col{
        margin-bottom: 0;
        .btn,
        a.btn,
        button.btn{
            width: 100%;
            padding: 0.375rem;
        }
    }
}

.text-underline{
    text-decoration: underline;
}

//link normal (con subrayado)
a.link-underline,
.link-underline{
    font-size: 0.8rem;
    color: $font-color-red-dark;
    text-decoration: underline;
    border: 0;
    cursor: pointer;
    &:hover{
        text-decoration: none;
    }
    &.disabled{
        color: $font-color-disabled;

    }
    //con icono
    &.ico{
        &::before{
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            padding-right: 0.15rem;
        }
        &.ico--edit{
            &::before{
                content: '\f044';
            }
        }
        &.ico--save{
            &::before{
                content: '\f0c7';
            }
        }
        &.ico--plus{
            &::before{
                content: '\f067';
            }
        }
        &.ico--eye{
            color: $font-color-red-dark;
            text-decoration: none;
            &::before{
                content: '\f06e';
            }
        }
    }
    &--blue{
        color: $color-blue-medium;
        text-decoration: underline;
        &:hover{
            color: $color-blue-medium;
            text-decoration: none;
        }
    }
}
.filter-tags__right{
    .link-underline{
        &::before{
            font-family: "Font Awesome 5 Pro";
            content: '\f0c7';
            font-weight: 900;
            display: inline-block;
            margin-right: 0.4rem;
        }
    }
}

// grupo de 3 botones

.group-buttons{
    display: flex;
    .btn{
        padding:0 8px;
        //&--red{
        //    border-radius:0;
        //}
        &--gray{
           &-left{
               border-radius: $border-radius 0 0 $border-radius;
           }
            &-cent{
                border-radius: 0;
            }
            &-right{
                border-radius: 0 $border-radius $border-radius 0;
            }
        }
        &--gray-selected{
            &-left{
                border-radius: $border-radius 0 0 $border-radius;
            }
            &-cent{
                border-radius: 0;
            }
            &-right{
                border-radius: 0 $border-radius $border-radius 0;
            }

        }
   }
}

a.btn.close.btn-span{
    @extend .btn-span;
}


// bloque de botones de listados
.block-btns{
    display: flex;
    align-items: center;
    &__checkbox{
        .Select2Componentundefined div{
            position: relative;
            label{
                position: initial !important;
            }
        }
    }
}

