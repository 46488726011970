//cuadros tipo visitas en dashaboard

.c-visit-square{
    min-height: 100px;
    //grupo títulos: h1,h2
    &__titles{
        margin-bottom: 0.6rem;
    }
    //título
    &__title{
        margin-bottom: 0;
        font-family: $font-secondary;
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
        line-height: $line-height - 0.3;
        color: $font-color-gray;
        word-break: break-word;
        @include mq('tablet-wide'){
            font-size: 1.1rem;
        }
        @include mq('desktop'){
            font-size: 1.250rem;
        }
    }
    //subtítulo
    &__subtitle{
       @extend .c-visit-square__title;
        font-weight: 300;
    }
    //h3
    &__thirdtitle{
        position: relative;
        bottom: 2px;
        margin: 0;
        font-family: $font-base;
        font-size: 2.125rem;
        font-weight: 100;
        line-height: 100%;
        color: $font-color-gray;
        @include mq('desktop'){
            font-size: 3.125rem;
        }
    }
    // boton flecha
    button.btn--arrow, a.btn--arrow, .btn--arrow{
        font-size: 2.125rem;
        @include mq('desktop'){
            font-size: 3.125rem;
        }
    }
    //listas
    &-list{
        margin: 0.4rem 0;
        padding: 0;
        list-style-type: none;
        &__item{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            font-family: $font-base;
            font-size: 0.750rem;
            font-weight: 400;
            text-align: left;
            text-transform: capitalize;
            line-height: $line-height;
            color: $font-color-dark;
            &:last-child{
                margin-bottom: 0;
            }
            //parte izquierda
            &-left{
                flex: 0 1 calc(50% - 0.5rem);
                text-align: left;
                margin: 0 0.5rem 0 0;
                //iconos
                &.ico{
                    display: flex;
                    align-items: center;
                    &::before{
                        font-family: "Font Awesome 5 Pro";
                        font-size: 1rem;
                        font-weight: 900;
                        margin-right: 0.5rem;
                    }
                    //wifi
                    &--wifi{
                        &::before{
                            content: '\f1eb';
                        }
                    }
                    &--green{
                        &::before{
                            color: $color-green-light;
                        }
                    }
                    &--yellow{
                        &::before{
                            color: yellow; //no se utiliza, al menos por ahora.
                        }
                    }
                    &--red{
                        &::before{
                            color: $color-red;
                        }
                    }
                    //lugar
                    &--place{
                        text-transform: uppercase;
                        &::before{
                            content: '\f041';
                            color: $color-blue-medium;
                        }
                    }
                }
            }
            &-right{
                @extend .c-visit-square-list__item-left;
                margin-left: 0.5rem;
                margin-right: 0;
            }
        }
    }
    //tablas
    &-table{
        width: calc(100% - 30px);
        margin: 1rem auto 0.5rem auto;
        td{
            vertical-align: middle;
            font-family: $font-base;
            font-size: 0.750rem;
            font-weight: 400;
            text-align: left;
            color: $font-color-dark;
            div{
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                color: inherit;
            }

        }
    }
}

//extends
.table.c-visit-square-table{
    @extend .c-visit-square-table;
}
.card.card-home p.c-visit-square-list__item-left{
    @extend .c-visit-square-list__item-left;
}
.card.card-home p.c-visit-square-list__item-right{
    @extend .c-visit-square-list__item-right;
}