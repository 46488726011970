.filepond--drop-label{
  font-size: 15px;
  text-align: center;
  padding: 15px;
  margin: 0 0 15px 0;
  color: #0C6CAC;
  border: 1px dashed #CED4DA;
  border-radius: 4px;
  cursor: default;
  background: #fff;
}

.filepond--label-action {
  height: 2.375rem;
  line-height: 2.375rem !important;
  padding: 0 0.813rem;
  margin: 0;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem !important;
  font-weight: 400;
  text-align: center;
   color: #373A3C;
  cursor: pointer;
  text-decoration: none !important;
  box-shadow: none;
  border: 1px solid #E9ECEF;
  border-radius: 0.250rem;
  background-color: #E9ECEF;
}

.filepond--root .filepond--drop-label {
  min-height: 7rem !important;
}

.filepond--drop-label.filepond--drop-label label{
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;
    color: #6C757D;
}

.uploaded-elements-container__file{
  position: relative;
  .dropdown-sublist{
    top: 35px;
    right: -125px;
  }
}
@media (max-width:640px) {
  .uploaded-elements-container__file .dropdown-sublist{
    right: -20px;
  }
}

.fileItem{
  width:100%;
  min-width: 135px;
  max-width: 135px;
  border-radius: 5px;
  background: #EEF5F9;
  border:1px solid #EEF5F9;
  margin-bottom: 15px;
  position: relative;
  //cabecera
  &-header{
    min-height: 28px;
    color: #666666;
    padding:5px 17px;
    text-align: center;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    a{
      color: #666666;
    }
    .fa{
      position: absolute;
      top: 7px;
      right: 5px;
      color: #D1322D;
      opacity: 0;
      visibility: hidden;
      transition: all 400ms ease;
    }
    span{
      cursor: pointer;
    }
    //hover sobre fileitem
    &:hover{
      .fileItem-header .fa{
        opacity: 1;
        visibility: visible;
      }
    }
  }
  //contenido
  &-content{
    background: #fff;
    & > div{
      position: relative;
      padding-bottom: 75%;
      overflow: hidden;
    }
    a{
      display: block;
      text-align: center;
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
    // img pdf
    &__tag{
      position: absolute;
      padding: 0;
      overflow: initial;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      img{
        width:110px;
      }
      .fas,
      .far,
      .fa{
        font-size: 2rem;
        position: absolute;
        color: #666666;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor:pointer;
      }
    }
  }
  // footer con info
  &-footer{
    font-size: 0.688rem;
    padding: 0.125rem 0.2rem;
    display: flex;
    justify-content: space-between;
    &__right{
      text-align: right;
      position: relative;
    }
    .fas{
      margin-right:0;
    }
  }
  //readonly
  &.readonly .fileItem-header{
    .fa{
      display: none;
    }
    span{
      cursor: default;
    }
  }

}

.delete-file {
  margin-top: 5px;
  margin-right: 5px;
}
.filepond--list.filepond--list {
  top: 8px !important;
}

// Imagenes ordenables

.uploaded-elements-container {
  .checkbox-list {
    padding: 0;
    &.axis {
      display: flex;
      flex-wrap: wrap;
      .drag-li {
        margin-right: 15px;
      }
    }
  }
}

//.ReactModal__Content.right-to-left .uploaded-elements-container .checkbox-list{
//  width: 100%;
//  max-width: unset;
//  min-width: unset;
//  height: auto;
//  min-height: unset;
//}

.uploaded-image {
  border: 1px solid #efefef;
  border-radius: 5px;
  background: rgba(255,255,255,0.75);
  margin-bottom: 1rem;
  display: flex;
  align-items: stretch;
  &.mainPhoto{
    border-color:#F92C20;
  }
  &.validate{
    background: #fafafa;
  }

  .form-group{
    margin: 0;
    input[type="radio"] + label{
      font-size: 0.875rem;
      color: #A5A6A7;
      margin: 0 2rem 0 0;
    }
    input[type="radio"] + label::before{
      top: 2px;
    }
    input[type="radio"]:checked + label{
      color: #212529;
      font-weight: 700;
    }
  }

  .image{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    max-height: 120px;
    margin-right: 2rem;
    box-shadow: 0 0 3px #ECECEC;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .content{
    flex: 1;
    color: #A5A6A7;

    .title{
      color: #212529;
      font-weight: 700;
    }

    .data{
      display: flex;
      flex-direction: row;
      align-items: center;
      div{
        margin-right: 2rem;
      }
    }
  }

  .dragHandle,
  .dragHandle-fake {
    padding: 1rem;
    border-right: 1px dashed #efefef;
    flex-shrink: 0;
    min-width: 70px;
  }
  .dragHandle{
    cursor: move;
  }
  .image-sortable-item {
    padding: 1rem;
    display: flex;
    flex: 1 1 100%;
  }

  button{
    margin-bottom: 1rem;
  }
}

.modal-push{
  .content{
    .data{
      flex-direction: column;
      align-items: flex-start;
      div{
        margin-bottom: 0.25rem;
      }
    }
  }
}
