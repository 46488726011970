body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*display: inline-grid;*/
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.leaflet-pane{
    z-index: 38!important;
}

.flexible-modal {
    position: absolute;
    z-index: 999;
    border: 1px solid #ccc;
    background: white;
}

.flexible-modal-mask {
    position: fixed;
    height: 100%;
    background: rgba(55, 55, 55, 0.6);
    top: 0;
    left: -99999px;
    right: 0;
    bottom: 0;
    z-index: 999;
}

.flexible-modal-resizer {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: se-resize;
    margin: 5px;
    border-bottom: solid 2px #333;
    border-right: solid 2px #333;
}

.flexible-modal-drag-area {
    background: rgba(22, 22, 333, 0.2);
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: move;
}

.fixed-bottom-calendar {
    position: fixed;
    right: 440px;
    bottom: 75px;
    left: 0;
    z-index: 1030;
}

.rowSlotNoSeleccionada {
    background-color: #F6F6F6;
}

.rowSlotSeleccionada {
    /*background-color: #f6f600;*/
}

.rowSlotIsFinished {
    background-color: rgba(161, 255, 174, 0.32);
}

.header-fixed-bottom {
    position: fixed;
    z-Index: 98;
    bottom: 0;
    /*pointer-events: none;*/
    left: 0;
    height: 75px;
    width: 100%;
    background-color: rgba(45, 59, 65, 0.2)
}


.header-fixed-top {
    position: fixed;
    z-Index: 98;
    top: 0;
    /*pointer-events: none;*/
    left: 0;
    height: 75px;
    width: 100%;
    background-color: rgba(45, 59, 65, 0.2)

}

