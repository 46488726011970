//buscar
.search-block{
    height: $search-height;
    font-size: $font-size-small;
    //media queries
    @include mq('tablet'){
        font-size: $font-size;
    }
    input{
        height: 100%;
        display: block;
        padding: 0 1em;
        border-right: 0;
        font-family: $font-base;
        font-size: 0.875em;
        font-weight: 400;
        color: $font-color-light;
    }
    button{
        &.btn{
            border-left: 0;
        }
    }
}
//texto número de contactos añadidos
.contacts-num,
p.contacts-num{
    margin: 0;
    font-family: $font-base;
    font-size: 1em;
    font-weight: 400;
    color: $font-color-dark;
    span{
        font-weight: 600;
    }
}
