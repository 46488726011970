
.imports {
  padding: 1em 0;

  .alert-success {
    margin-top: 1em;
    border-radius: 4px;
    background-color: #dbf3e5;
    text-align: center;
    padding: 0.75rem 1.25rem;
    border: 1px solid #b0e4c6;

    span {
      font-size: 0.875rem
    }
  }

  span {
    margin: 5px 0;
  }

  .imports-steps {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;

    .fa-arrow-right {
      color: #cfcfcf;
      background-color: #f1f1f1;
      padding: 0.7em 0.8em;
      margin: 0 15px 0 15px;
      border-radius: 5px;
    }

    span {
      &.inactive {
        color: #cfcfcf;
        background-color: #f1f1f1;
        padding: 0.5em 0.8em;
        margin: 0 15px 0 15px;
        border-radius: 5px;
      }

      &.active {
        @extend .inactive;
        border: 1px solid #35485c;
        color: #35485c;
      }
    }

  }

  .table-imports {
    margin-top: 1em;
    width: 100%;
    max-width: 100%;
    background-color: #0000;

    .fa-arrow-right {
      color: #cfcfcf;
      background-color: #f1f1f1;
      padding: 0.5em 0.8em;
      margin: 0 15px 0 15px;
      border-radius: 5px;
    }


    thead {
      height: 30px;
      border-bottom: 2px solid;
      border-top: 2px solid;

      th {
        text-transform: uppercase;
        font-size: 0.8525rem;
        font-weight: bold;
        width: 500px;

        &.tight-column {
          width: 310px;
        }
      }

    }

    tbody {

      tr {
        &.title-group {
          border-bottom: 2px solid #cfcfcf;

          td {
            padding-top: 1rem;
          }
        }

      }

      td {
        padding: 0.2em 1.6em 0.2em 0;
        height: 15px;

        &.align-end {
          text-align: end;

          i {
            margin: 0 0 0 15px;
          }
        }

        &.align-start {
          text-align: start;

          i {
            margin: 0 15px 0 0;
          }
        }

      }
    }

    .table-comment {
      color: $color-blue-medium;
      font-style: italic;

      span {
        font-weight: 400;
        font-style: italic;
      }
    }
  }
}





