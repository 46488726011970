.c-icons-capacitat{
  display: block;
  width: 83%;
  margin-left: auto;
  margin-top: -4px;
  span{
    &.fas, &.fa, &.far{
      font-size:0.7rem;
      color:$color-gray-dark;
    }
  }
}
